import React, { useEffect, useState } from 'react';

// LIBRARIES

// MATERIAL
import { Typography } from '@mui/material';

// FUNCTIONS
import { usePosition } from '../hooks/usePosition';
import { useCamera } from '../hooks/useCamera';

// COMPONENTS
import ScanProcess from '../components/ScanProcess';
import { PermissionError } from '../components/PermissionError';

export default function Scan({ deviceKey }) {
  const { latitude, longitude, positionError } = usePosition();
  const { cameraError } = useCamera();

  console.log(deviceKey)

  if (cameraError === 'Prompt') {
    return (
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
          textAlign: 'center',
        }}
        variant='h5'
      >
        Checking camera permission...
      </Typography>
    );
  }

  if ((!deviceKey && positionError) || (deviceKey?.distanceEnabled && positionError) || cameraError) {
    console.log(deviceKey, positionError, cameraError);
    return (
      <>
        {positionError && <PermissionError serviceName='location' />}
        {cameraError === 'denied' && <PermissionError serviceName='camera' />}
      </>
    );
  }

  console.log(latitude, longitude);

  return (
    <>
      {((latitude && longitude) || (deviceKey && !deviceKey.distanceEnabled)) && (
        <ScanProcess
          latitude={deviceKey && !deviceKey.distanceEnabled ? 0.0 : latitude}
          longitude={deviceKey && !deviceKey.distanceEnabled ? 0.0 :longitude}
          // encodedData={encodedData}
          deviceKey={deviceKey}
        />
      )}
    </>
  );
}
